import * as React from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

import { paintTexture } from '../theme'

const BigLetterWrapper = styled(animated.span)`
    margin-bottom: 0.25em;

    ${props => props.paint && `background: url(${paintTexture()})`};
    ${props => props.paint && '-webkit-background-clip: text'};
    ${props => props.paint && '-webkit-text-fill-color: transparent'};

    background-size: cover;

    @media screen and (min-width: 601px) {
        font-size: 65px;
    }

    @media screen and (max-width: 600px) {
        font-size: 10vw;
    }
`;

const BigLetter = ({ position, children, paint, delay }) => {
    const slide = useSpring({
        from: {
            opacity: 0,
            marginLeft: -20,
        },
        to: {
            opacity: 1,
            marginLeft: 0,
        },
        delay: (100 * position) + (delay ?? 0)
    });

    const start = position * 100;
    const paintSlide = useSpring({
        loop: true,
        from: {
            backgroundPositionX: start
        },
        to: {
            backgroundPositionX: start + 2048
        },
        config: {
            duration: 90 * 1000,
        },
    });

    return <BigLetterWrapper style={ paint ? {...paintSlide, ...slide} : slide} paint={paint}>{children}</BigLetterWrapper>;
};

const BigWord = ({ children, paint, delay }) => {
    return children
        .split("")
        .map((letter, i) => <BigLetter paint={paint} position={i} delay={delay}>{letter}</BigLetter>)
};

export default BigWord;