import * as React from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

import { Colors } from '../constants'

const DefenitionText = styled(animated.p)`
    font-size: 13px;
    font-family: 'Cocogoose Pro Italic';
    color: ${Colors.altText};

    margin-bottom: 5vh;
`;

const Defenition = ({ children, delay }) => {
    const fadeIn = useSpring({
        from: {
            opacity: 0,
            transform: 'translateY(30px)'
        },
        to: {
            opacity: 1,
            transform: 'translateY(0)'
        },
        delay
    });

    return <DefenitionText style={fadeIn}>{children}</DefenitionText>
};

export default Defenition;