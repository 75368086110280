import '../global.css'

import * as React from 'react'
import BasePage from '../components/basePage'

import BigWord from '../components/bigWord'
import Defenition from '../components/defenition'
import { FlexContainer, VerticalCenter } from '../components/flex'


const IndexPage = () => {
    return (
        <BasePage pageTitle='Vivid Fermentation Project'>
            <FlexContainer marginTop={1}>
                <VerticalCenter>
                    <div>
                        <BigWord paint delay={500}>Vivid</BigWord>
                        <Defenition delay={700}>(adjective) producing powerful feelings or strong, clear images in the mind</Defenition>
                        <BigWord delay={1500}>Fermentation</BigWord>
                        <Defenition delay={2500}>(verb) the chemical breakdown of a substance by bacteria, yeasts, or other microorganisms</Defenition>
                        <BigWord delay={2600}>Project</BigWord>
                        <Defenition delay={3600}>(noun) an individual or collaborative enterprise that is carefully planned to achieve a particular aim</Defenition>
                    </div>
                </VerticalCenter>
            </FlexContainer>
        </BasePage>
    );
}

export default IndexPage
